table{
    width: 100%;
}

.sideContainer{
    border-right: 40px solid #ddd;
    background: #FFFFFF;
    /* height: 100vh;
    width: 100vw; */
    height: 800px;
    width: 600px;
    border-spacing: 1em .5em;

}

table td{
    cursor: pointer;
    padding: 15px 20px;

}

table td a {
    text-decoration: none;
    color: #000;
}

.logoImg{
    width: 190px;
}

table td.active {
    border: 1px solid #fafafa;
    background: aliceblue;
    width: 1.5em;
    height: 1.5rem;
}
@media screen and (max-width: 700px) {
    .sideContainer {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
  }
@media screen and (max-width: 400px) {
    .sideContainer td.active {
      text-align: center;
      float: none;
    }
  }