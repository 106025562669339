@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden; 
  font-family: Montserrat, sans-serif;
}
@font-face {
  font-family: 'Gill Sans';
  src: local('Gill Sans'), url(./assets/fonts/GIL.ttf) format('ttf');
}

.editProfileClass {
  color: white;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.body-scroll {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}
.mainGB {
  background-color: #f6f6f6;
}