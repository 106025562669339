.lbl {
  display: flex;
  align-items: self-end;
  position: absolute;
  margin-top: 5px;
  margin-left: 15px;
  font-size: 20px;
  cursor: text;
}
.cc-lbl {
  font-size: 18px;
  color: #727577;
  padding-right: 5px;
  cursor: text;
}
.cc-req {
  margin-top: -4px;
  color: #12b2cb;
}

.me-2 {
  padding-left: 35px !important;
}

input {
  height: 35px;
  width: 155px;
  padding: 5px;
  border: 1px solid #04021d;
  border-radius: 5px;
}

input:disabled,
input[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.table>:not(caption)>*>* {
  padding-left: 0.3rem !important;
  padding-right: 0.3rem !important;
}

.ml-3 {
  margin-left: 10px !important;
}
/* .bottomContainer {
  width: 79.5%;
  position: fixed;
  bottom: 15px;
} */

.disclaimer {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: inset 0px -4px 7px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.disclaimerCheck {
  width: 25px;
  height: 25px;
  background: #12b2cb;
  border: 1px solid #12b2cb;
  border-radius: 2px;
  cursor: pointer;
}

.disclaimerLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #263238;
  position: relative;
  left: -45px;
}
.status {
  width: 90px;
}
.disclaimerContent {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #686777;
}

.rbt .rbt-aux {
  position: absolute;
  right: 5px;
  top: 4px;
}
.text-right{
  text-align: right;
}