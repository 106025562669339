.wrapper {
  display: flex;
}

.wrapper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.bell-icon {
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 153, 112, 0.08);
  border-radius: 5px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: 20px;
}

.user-image {
  width: 48px;
  height: 48px;
  left: 7px;
  top: 0px;
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
  margin-left: 20px;
  cursor: pointer;
}

.notificationDot {
  width: 15px;
  height: 15px;
  background: #12b2cb;
  border-radius: 50%;
  position: relative;
  left: -10px;
  top: -8px;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  color: #ffff;
  margin-left: -15px;
}
