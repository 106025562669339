.pagination-outer {
  display: flex;
  float: right;
  text-align: center;
}
.pagination {
  font-family: 'Gill Sans', 'Gill Sans MT' ,sans-serif;
  display: flex;
  justify-content: flex-end !important;
}

.page-item {
  display: flex;
  margin-right: 10px;
}

.page-link {
  width: 45px;
  /* height: 45px; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: black;
}
